import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import TimeAgo from 'react-timeago'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.ghostPost
    const readingTime = readingTimeHelper(post)

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
                <script src={ `/js/post.js` } type="text/javascript" />
            </Helmet>
            <Layout isPostPage={true}>
                <article>

                <div className={"section-featured " + (post.featured_image ? " is-featured-image" : " no-featured-image")}>
                    { post.feature_image ?
                        <div className="featured-image" style={{"backgroundImage" : `url(${post.feature_image})`}}></div>
                    :null}
                    <div className="featured-wrap flex">
                        <div className="featured-content">
                            <div className="tags-wrap">
                                { post.featured ? <span className="featured-label global-tag"><span>"Featured"</span></span> : null}
                                { post.tags.map((tagItem) => (
                                        <Link className="post-tag global-tag" key={tagItem.uuid} to={ "/tag/" + `${tagItem.slug}` }>{tagItem.name}</Link>
                                    )
                                )}
                            </div>
                            <h1 className="white">{post.title}</h1>
                            <div className="item-meta white">
                                <span>by </span>
                                <a className="author-name white" href={ `/author/` + post.primary_author.slug }>{ post.primary_author.name} </a>
                                <TimeAgo date={post.created_at} />
                                <span className="reading-time">{readingTime}</span>
                            </div>
                        </div>
                    </div>
                </div>

                    <div className="section-post wrap">
                        <div className={ `post-wrap` + (post.feature_image ? null : ` no-image`) } dangerouslySetInnerHTML={{ __html: post.html }}></div>
                    </div>
                </article>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
